import React, {useEffect, useMemo, useRef, useState,useCallback} from 'react';
import {postData} from '../../util/request';
import './index.css';
import {FreshIcon, QrcodeIcon} from "../../util/icon";

const Qrcode=require('qrcode');

interface anyObject {
    [key: string]: any;
}

export default function ScanQrcode(props:any) {
    // pageState [loading|scanning|reloading]
    const [pageState,setPageState]=useState('loading');
    const [SignInQR,setSignInQR]=useState('');
    const [SignInInfo,setSignInInfo]=useState<anyObject>({});


    // init QRcode
    useEffect(()=>{
        postData('auth ', {
            "tokenType": "mainweb"
        }).then(responseData => {
                if(responseData?.code===1000){
                    const tempQrInfo=JSON.parse(JSON.stringify(responseData.data))
                    delete tempQrInfo.loginQrUrl;
                    setSignInInfo(tempQrInfo);
                    setPageState('scanning');
                    Qrcode.toDataURL(JSON.stringify(tempQrInfo),{margin:0},(err:any,url:string)=>{
                        setSignInQR(url)
                    })
                }else{
                    console.log(responseData.message)
                }
            })
            .catch(error => {
                console.log("error")
            });
    },[])



    const stopReducer=(timer:any)=>{
        sessionStorage.clear();
        setPageState('reloading')
        return () => clearInterval(timer);
    }

    const freshCode = ()=>{
        sessionStorage.clear();
        setPageState('loading');
        postData('auth ', {
            "tokenType": "mainweb"
        }).then(responseData => {
            if(responseData?.code===1000){
                const tempQrInfo=JSON.parse(JSON.stringify(responseData.data))
                delete tempQrInfo.loginQrUrl;
                setSignInInfo(tempQrInfo);
                setPageState('scanning');
                Qrcode.toDataURL(JSON.stringify(tempQrInfo),{margin:0},(err:any,url:string)=>{
                    setSignInQR(url)
                })
            }else{
                console.log(responseData.message)
            }
        })
            .catch(error => {
                console.log("error")
            });
    }

    useMemo(()=>{
        if(SignInInfo?.sid){
            const timer = setInterval(() => {
                const {sid,expireTs,expireAt}=SignInInfo;
                if(Math.round(Date.now()/1000) <= expireTs){
                    postData('auth ', {
                        "tokenType": "mainweb",
                        sid:sid
                    }).then(responseData => {
                        if(responseData?.code===1000){
                            // console.log("轮训状态",responseData)
                            const {id='',token='',smileCode='',im}=responseData.data;
                            if(id&&(/\S/.test(id))){
                                sessionStorage.setItem('id', id);
                            }
                            if(token&&(/\S/.test(im?.userSig))){
                                sessionStorage.setItem('userSig', im?.userSig);
                            }
                            sessionStorage.setItem('smileCode', smileCode);
                            //do redirect
                            if(sessionStorage.getItem('id')&&sessionStorage.getItem('userSig')){
                                clearInterval(timer);
                                props.redirect()
                            }

                        }
                    }).catch(error => {
                        console.log("error")
                        stopReducer(timer);
                    });
                }else{
                    stopReducer(timer)
                }
            }, 10000);
        }
    },[SignInInfo?.sid])


    useMemo(()=>{
        if(pageState==='reloading'||pageState==='loading'){
            sessionStorage.clear();
        }
    },[pageState])
    return (
        <div className={'qr-content'}>
            <div className={'qr-img'}>
                <div style={{position:'relative'}}>
                    <img src={SignInQR}/>
                    {pageState==='loading'&&
                        <div className={'qr-img-loading hori-verti-md'}>
                            <QrcodeIcon
                                width={60}
                                height={60}
                                className={'qr-icon'}
                            />
                        </div>
                    }
                    {pageState==='reloading'&&
                        <div className={'qr-img-loading hori-verti-md'}>
                            <FreshIcon
                                width={60}
                                height={60}
                                className={'qr-icon'}
                                onClick={()=>{
                                    freshCode()
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className={'qr-describe'}>
                <h4>Scan to log in on FacePro</h4>
                <p>Log in on phone to use FacePro on web</p>
            </div>
            {(pageState==='loading'||pageState==='reloading')&&
                <div className={'qr-mask'}>
                </div>
            }
        </div>
    );
}
