import React, {ReactNode, useEffect, useMemo, useState, forwardRef, useRef} from "react";
import {
    ConversationSearchInput,
    ConversationSearchResult,
    useTUIKitContext,
    useTUIMessageInputContext,
    TUIConversation,useTUIChatActionContext,
    DivWithEdit,
    TUIChat, Popup,TUIMessage,messageShowType,
    Avatar,defaultUserAvatar,defaultGroupAvatarWork, Model, TUIChatHeader,Switch,
    Icon, IconTypes,TUIMessageInputDefault
} from "@tencentcloud/chat-uikit-react";
import TIM, {Message} from "tim-js-sdk";
import {
    CloseIcon,
    EditIcon,
    QrcodeIcon,
    ArrowRightIcon,
    SilenceIcon,
    SendIcon
} from "../util/icon";
import {useDebounce} from '../util/hooks';
import {searchAttribute,base64Encode} from '../util/common';
import classNames from "classnames";
import "./index.css";
import "./silence.css";
import {MESSAGE_TYPE_NAME} from "../util/upload/uploadhooks";
import PreUpload from './preUpload';
import {EmojiPicker} from './emojiPicker';
import {formatEmojiString} from '../util/emoji/emojiMap';
import {handleTipGrpUpdatedA} from "../util/textDecorate";
import Audio from "./audio";
import {AtMemberPicker} from './atMemberPicker';


interface FaceChatTabProps{
    conversation:any;
    chatId?:string;
    removeCurrentChat?:any;
    isActive?:boolean;
    [key:string]:any;
}
const Qrcode=require('qrcode');

const FaceChatTab=forwardRef((props:FaceChatTabProps, ref) => {
    const {
        tim, customClasses, conversation, setActiveConversation, setTUIProfileShow,
    } = useTUIKitContext("TUIConversationList");

    const {conversation:currentConversation,chatId,removeCurrentChat,freshCurrentChat,isActive,...restProps}=props;
    const uploadRef=useRef(null);
    const memberPickerRef=useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [silentAll, setSilentAll] = useState(false);
    const [memberList,setMemberList]=useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [FilterMember,setFilterMember]=useState([]);

    const sendMessageToParent = (message:string) => {
        console.log("Received message from parent:", message);
    };


    React.useImperativeHandle(ref, () => ({
        sendMessageToParent,
    }));

    const GroupChatQrContent=(props:any)=>{
        const {avatar,name,groupID}=currentConversation.groupProfile;
        const {qrInfo}=props;
        return (
            <div className={"qr-inner"}>
                <Avatar image={avatar || defaultGroupAvatarWork} size={40}/>
                <div className={"profile-name qr-inner-name"}>{name}</div>
                <div className={"qr-inner-qrimg"}>
                    <img src={qrInfo} alt=""/>
                </div>
                <div className={"qr-inner-button"}>
                    Scan QR Code
                </div>
            </div>
        );
    };
    const withPopup = (WrappedComponent:any) => {
        const WithPopup = (props:any) => {
            const [showPopup, setShowPopup] = useState(false);
            const [className, setClassName] = useState("");
            const [qrInfo,setQrInfo]=useState('');
            const openPopup = () => {
                if(showPopup===false){
                    setShowPopup(true);
                }
            };

            const handleShow = () => {
                setShowPopup(!showPopup);
            };
            const handleVisible = () => {
                setClassName("qr-pop-content");
            };

            useEffect(()=>{
                if(currentConversation?.type==='GROUP'){
                    Qrcode.toDataURL(JSON.stringify(`https://qr.myfacedao.com/?groupId=${base64Encode(currentConversation?.groupProfile?.groupID)}&version=1${sessionStorage.getItem('smileCode')?`&otherSmileCode=${sessionStorage.getItem('smileCode')}`:``}`),(err:any,url:string)=>{
                        setQrInfo(url)
                    })
                }
            },[]);

            return (
                <div style={{position:"relative"}}>
                    <QrcodeIcon
                        width={18}
                        height={18}
                        onClick={handleShow}
                    />
                    <Popup
                        className={classNames(`input-plugin-popup-box`,className)}
                        show={showPopup}
                        close={handleShow}
                        handleVisible={handleVisible}
                    >
                        <WrappedComponent qrInfo={qrInfo}  {...props} />
                    </Popup>
                </div>
            );
        };
        return WithPopup;
    };
    const WrapperQrPopup=withPopup((props:any)=>GroupChatQrContent(props));


    const EditGroupProfile=()=>{
        const {groupID,name,notification,...restProps}=currentConversation.groupProfile;
        const [isEditName, setIsEditName] = useState("");
        const [groupInfo,setGroupInfo]=useState(currentConversation.groupProfile);
        useEffect(()=>{
                tim.getGroupProfile({
                    groupID: groupID,
                }).then(function(imResponse) { // Got successfully
                    setGroupInfo(imResponse.data.group)
                }).catch(function(imError) { // Getting failed
                    console.warn('getGroupAttributes error:', imError); // Error information
                });
        },[groupID])
        const editText = (data?:any) => {
            let key = "";
            switch (data?.name) {
                case "groupName":
                    key = "name";
                    break;
                case "notification":
                    key = "notification";
                    break;
                default:
                    setIsEditName("");
                    return;
            }
            const options = {
                [key]: data.value,
            };
            confirm(options);
        };
        const handleSetEditName = (name:string) => {
            setIsEditName(name);
        };
        const confirm = (options:any) => {
            update(options);
            setIsEditName("");
        };

        const update=(options:any)=>{
            // save the profileInfo
            Object.keys(options).map(i=>{
                const newVal=options[i];
                const oldVal=searchAttribute(groupInfo,i);
                if(newVal&&oldVal!==newVal){
                    tim.updateGroupProfile({
                        ...{
                            groupID: groupID
                        },...options
                    }).then(function(imResponse) {
                        console.warn('updateGroupProfile success:', imResponse); // Error information
                    }).catch(function(imError) {
                        console.warn('updateGroupProfile error:', imError); // Error information
                    });
                }else{
                    return
                }
            })
        };

        return (
            <div className={"edit-profile-inner"}>
                <ul className="tui-profile-list">
                    <li className="tui-profile-list-item" key={"groupname"}>
                        <h4>GroupName</h4>
                        <DivWithEdit
                            className="tui-profile-div-with-edit"
                            classEditName="tui-profile-edit"
                            name={"groupName"}
                            value={name}
                            type={"text"}
                            toggle={handleSetEditName}
                            isEdit={isEditName === "groupName"}
                            confirm={editText}
                            close={() => { setIsEditName(""); }}
                        />
                    </li>
                    <li className="tui-profile-list-item" key={"notice"}>
                        <h4>Notice</h4>
                        <DivWithEdit
                            className="tui-profile-div-with-edit"
                            classEditName="tui-profile-edit"
                            name={"notification"}
                            value={notification}
                            type={"text"}
                            toggle={handleSetEditName}
                            isEdit={isEditName === "notification"}
                            confirm={editText}
                            close={() => { setIsEditName(""); }}
                        />
                    </li>
                    <li className="tui-profile-list-item">
                        <div style={{display:"flex",justifyContent:"space-between", alignItems: "center"}}>
                            <h4>Silence</h4>
                            <ArrowRightIcon
                                width={18}
                                height={18}
                                onClick={()=>{
                                    setShowModal(true);
                                    getMemberList(groupID)
                                }}
                            />
                        </div>
                    </li>
                </ul>
            </div>
        );
    };
    const withProfilePopup = (WrappedComponent:any) => {
        const WithProfilePopup = (props:any) => {
            const [showPopup, setShowPopup] = useState(false);
            const handleShow = () => {
                setShowPopup(!showPopup);
            };

            return (
                <div>
                    <EditIcon
                        width={18}
                        height={18}
                        onClick={handleShow}
                    />
                    <Popup
                        className={`input-plugin-popup-box edit-profile-content`}
                        show={showPopup}
                        close={handleShow}
                    >
                        <WrappedComponent {...props} />
                    </Popup>
                </div>
            );
        };
        return WithProfilePopup;
    };
    const WrapperProfilePopup=withProfilePopup(()=>EditGroupProfile());


    const FaceTuiHeader=(props:any)=>{
        const {conversation:chatConversation,...restProps}=props;
        return  (
            <div className={"chat-header tui-chat-header"}>
                <div className={"tui-chat-header-left"}>
                    <CloseIcon
                        width={18}
                        height={18}
                        onClick={()=>{
                            removeCurrentChat(chatConversation?.conversationID);
                        }}
                    />
                </div>
                <div className={"header-content"}>
                    <h3 className={`tui-chat-header title custom-tui-chat-header`}>{chatConversation?.userProfile?.nick||chatConversation?.groupProfile?.name}{
                        (
                            chatConversation.unreadCount!==0&&`(${chatConversation.unreadCount>99?`+`:``}${chatConversation.unreadCount})`
                        )
                    }</h3>
                </div>
                <div className={"tui-chat-header-right custom-tui-chat-header-right"} style={{display:chatConversation.type==="GROUP"?"flex":"none"}}>
                    <WrapperQrPopup/>
                    <WrapperProfilePopup/>
                </div>
            </div>
        );
    };

    function muteSingleMember (groupID:string,member:any,mm:number){
        tim.setGroupMemberMuteTime({
            groupID:groupID,
            userID: member?.userID,
            muteTime: 60 * mm
        }).then(function(imResponse) {
            getMemberList(groupID)
        }).catch(function(imError) {
            console.warn('setGroupMemberMuteTime error:', imError); // Error information
        });
    }

    const silentChange = (e:any,groupID:string) => {
        setSilentAll(e.target.checked);
        if(e.target.checked){
            // mute all
            const muteMember=memberList.filter((i:any)=>['Owner','Admin'].includes(i?.role)===false);
            muteMember.map((i:any)=>{
                muteSingleMember(groupID,i,10)
            })
        }else{
            // unmuted all
            // memberList.map((i:any)=>{
            //     muteSingleMember(groupID,i,0)
            // })
            // console.log("禁言列表");
        }
    };


    const isMute=(member:any)=>{
        const {muteUntil,...restProps}=member;
        if(muteUntil * 1000  > Date.now()){
            return true
        }
        return false
    }
    const getMemberList=(groupId:string)=>{
        const promise = tim.getGroupMemberList({ groupID: groupId, count: 30, offset:0 }); // Pull 30 group members starting from 0.
        promise.then(function(imResponse) {
            // console.log("imResponse.data",imResponse.data)
            setMemberList(imResponse.data.memberList);
            setFilterMember(imResponse.data.memberList);
        }).catch(function(imError) {
            console.warn('getGroupMemberProfile error:', imError);
        });
    }



    const handleSearchValueChange = (e:any)=>{
        setSearchValue(e);
        const filterArr=memberList.filter((i:any)=>{

            if(i?.userID.includes(e)){
                return i
            }
            if(i?.nick.includes(e)){
                return i
            }
        })
        setFilterMember(filterArr)
    }
    const debouncedFunction = useDebounce<any>(handleSearchValueChange, 500);

    const SubmitWithInput=(props:any)=>{
        const {setText,text,textareaRef,insertText,setCursorPos,focus,handleChange}=useTUIMessageInputContext('TUIMessageInput');
        const {sendMessage}=useTUIChatActionContext('TUIMessageInput');
        const [sendSomeone,setSendSomeone]=useState<any[]>([]);
        const [sendAll,setSendAll]=useState<boolean>(false);
        const [memberList,setMemberList]=useState<string[]>([]);
        // get MemberList for pickerComponent
        useEffect(()=>{
            if(currentConversation.type==='GROUP'){
                const promise = tim.getGroupMemberList({ groupID: currentConversation?.groupProfile?.groupID, count: 500, offset:0}); // Pull 30 group members starting from 0.
                promise.then(function(imResponse:any) {
                    setMemberList(imResponse.data.memberList.filter((i:any)=>i.userID!==sessionStorage.getItem('id')));
                }).catch(function(imError:any) {
                    console.warn('getGroupMemberProfile error:', imError);
                });
            }
        },[currentConversation?.groupProfile?.groupID])

        // send a Direct Message
        const sendDirectMessage = (user:any,isAll?:boolean)=>{
            if(insertText){
                if(isAll){
                    setSendAll(true);
                    insertText('all');
                }else{
                    const arr=[user];
                    setSendSomeone([...sendSomeone, ...arr])
                    insertText(user?.nick)
                }
            }
        }

        if(currentConversation.type==='GROUP'&&memberPickerRef&&memberPickerRef?.current){
            const pattern =/@$/;
            if(text&&pattern.test(text)){
                (memberPickerRef?.current as any).handleVis()
            }else{
                (memberPickerRef?.current as any).handleHide()
            }
        }
        return (
            <>
                <TUIMessageInputDefault/>
                <SendIcon
                    width={20}
                    height={20}
                    className={'transmitter-files'}
                    onClick={()=>{
                        if(uploadRef&&uploadRef?.current){
                        // @ts-ignore
                        const fileList=uploadRef?.current?.sendCurrentFileList();
                        fileList.map((file:any)=>{
                            switch (file.type) {
                                case 'image/png':
                                // @ts-ignore
                                    uploadRef?.current?.sendUploadMessage({file},MESSAGE_TYPE_NAME.IMAGE)
                                    break;
                                case 'video/mp4':
                                // @ts-ignore
                                    uploadRef?.current?.sendUploadMessage({file},MESSAGE_TYPE_NAME.VIDEO)
                                    break;
                                default: // @ts-ignore
                                    uploadRef?.current?.sendUploadMessage({file},MESSAGE_TYPE_NAME.FILE)
                                }
                            })
                            // @ts-ignore
                        uploadRef?.current?.removeAll();
                        if(text&&sendMessage&&setText){
                            const { type, userProfile, groupProfile} = currentConversation;
                            //@nick:message
                            if(sendSomeone && type === 'GROUP'){
                                const sendAllArr=[TIM.TYPES.MSG_AT_ALL];
                                const sendMemberIDArr=sendSomeone.map(person => person.userID);
                                const message = tim.createTextAtMessage({
                                    to: groupProfile?.groupID,
                                    conversationType: type,
                                    payload: {
                                        text:formatEmojiString(text),
                                        atUserList:sendAll?[...sendAllArr,...sendMemberIDArr]:sendMemberIDArr
                                    },
                                });
                                sendMessage(message).then(()=>{
                                    setText('')
                                    sendSomeone.length>0&&setSendSomeone([])
                                    sendAll&&setSendAll(false)
                                });
                            }else{
                                const message=tim.createTextMessage({
                                    to: type === 'GROUP' ?groupProfile?.groupID: userProfile?.userID,
                                    conversationType: type,
                                    needReadReceipt:true,
                                    payload:{
                                        text:formatEmojiString(text)
                                    }
                                })
                                sendMessage(message).then(()=>{
                                    setText('')
                                });
                            }
                        }
                        }
                    }}
                />
                {
                    currentConversation.type==="GROUP"&&<AtMemberPicker  ref={memberPickerRef} conversation={currentConversation} tim={tim} sendDirectMessage={sendDirectMessage} memberList={memberList}/>
                }
            </>
        )
    }

    return (
        <div
            key={`chat-item-${chatId}`}
            className={
                classNames({
                    'chat-item':true,
                    'chat-active':isActive
                })
            }
            onClick={()=>{
                if(!isActive){
                    setActiveConversation(currentConversation)
                }
            }}
        >
            <TUIChat
                key={chatId}
                conversation={currentConversation}
                TUIChatHeader={(headerProps) => <FaceTuiHeader  {...headerProps}/>}
                onMessageRecevied={(updateMessage,event)=>{
                    updateMessage(event.data)
                    if(isActive){
                        tim.setMessageRead({conversationID: currentConversation?.conversationID}).then(function(imResponse) {
                        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
                            console.log("====",imResponse)
                        }).catch(function(imError) {
                        // 已读上报失败
                        console.warn('setMessageRead error:', imError);
                        });
                    }
                }}
                TUIMessage={(props)=>{
                    const {message,...restProps}=props
                    // todo sysytem info decorate
                    // if(props?.message.type === 'TIMGroupTipElem'){
                    //     return <TUIMessage
                    //         isShowTime={false}
                    //         TUIMessage={(props, context)=>{
                    //             return <p style={{
                    //                 color: '#7a7a7a',
                    //                 fontSize: '12px',
                    //                 fontWeight: 400,
                    //                 lineHeight: '14px',
                    //                 padding: '10px',
                    //                 textAlign:'center'
                    //             }}>{handleTipGrpUpdatedA(message)}</p>
                    //         }}
                    //         {...props}
                    //     />
                    // }
                    return <TUIMessage
                        showAvatar={messageShowType.ALL}
                        showName={messageShowType.IN}
                        className={classNames({
                            'face-message':true,
                            'face-message-status':message.flow==='out'&&message.isPeerRead===false
                        })}
                        AudioElement={(props)=>{
                            return <Audio {...props}/>
                        }}
                        isShowTime={false}
                        suffix={message.flow==='out'&&message.isPeerRead===false?(<div className="read-status">unread</div>):''}
                        {...props}
                    />
                }}
                TUIMessageInputConfig={{
                    pluginConfig:{
                        isEmojiPicker:false,
                        isImagePicker:false,
                        isVideoPicker:false,
                        isFilePicker:false,
                        showNumber:2,
                        plugins:[<EmojiPicker/>,<PreUpload ref={uploadRef} {...currentConversation}/>]
                    },
                }}
                TUIMessageInput={()=><SubmitWithInput/>}
                className={"chat-tuikit"}
            />
            {
                showModal&& <Model
                children={
                    <div className={"silence"}>
                        <div className={"silence-header"}>
                            <TUIChatHeader
                                title={`${currentConversation.groupProfile.name} Setting`}
                                conversation={currentConversation}
                                avatar={
                                    <Avatar
                                        image={currentConversation?.groupProfile?.avatar||defaultGroupAvatarWork}
                                        size={50}
                                    />
                                }
                                headerOpateIcon={<CloseIcon width={30} height={30} onClick={() => { setShowModal(false); }}/>}
                            />
                        </div>
                        <div className={"silence-content"}>
                            <div className={"flex-content"}>
                                <div className={"flex-content-average silence-all"}>
                                    Only admin or owner can speak
                                    <Switch
                                        checked={silentAll}
                                        onChange={(e)=>silentChange(e,currentConversation.groupProfile.groupID)}
                                        checkedColor={"#b3f416"}
                                        className={""}
                                    />
                                </div>
                                <div className={"flex-content-average"}>
                                    <ConversationSearchInput
                                        value={""}
                                        clearable
                                        onChange={(e)=>{
                                            debouncedFunction(e?.target?.value||'')
                                        }}
                                    />
                                </div>
                            </div>
                            {(FilterMember.length!==0)?<div className={"silence-member"}>
                                {(searchValue?FilterMember:(memberList&&memberList)).map((i:any,index:number)=>(
                                    <div className={classNames({
                                        'silence-member-item':true,
                                        'mute-status':isMute(i)&&true//设置禁言
                                    })}>
                                        <Avatar
                                            image={i?.avatar||defaultUserAvatar}
                                            size={50}
                                            onClick={()=>{
                                                muteSingleMember(currentConversation.groupProfile.groupID,i,10)
                                            }}
                                        />
                                        <h3 className={'silence-member-item-title'}>{i?.nick}</h3>
                                        {isMute(i)&& <SilenceIcon
                                            width={24}
                                            height={24}
                                            className={'mute-status-icon'}
                                            onClick={() => {
                                                muteSingleMember(currentConversation.groupProfile.groupID,i,0)
                                            }}
                                        />}
                                    </div>

                                ))}
                            </div>:(
                                <div className="conversation-search-result no-result">
                                    <Icon className="no-result-icon" type={IconTypes.CRY} width={42} height={42} />
                                    <span className="no-result-message">{`No results for "${searchValue}"`}</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
                />
            }
        </div>
    );

});
export default FaceChatTab;
