import React, {useEffect, useMemo, useRef, useState} from 'react';
import {TUIKit,TUIChat, useTUIKit, useTUIKitContext} from '@tencentcloud/chat-uikit-react';
import '@tencentcloud/chat-uikit-react/dist/cjs/index.css';
import TIM, { ChatSDK } from 'tim-js-sdk/tim-js-friendship';
import TIMUploadPlugin from 'tim-upload-plugin';
import Layout from '../components/layout';
import Profile from '../components/profile';
import WebChat from '../im';
import './index.css';

// create tim instance && login
const init = async ():Promise<ChatSDK> => {
    return new Promise((resolve, reject) => {
        const tim = TIM.create({ SDKAppID:20001307 });
        tim?.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
        const onReady = () => { resolve(tim); };
        tim.on(TIM.EVENT.SDK_READY, onReady);
        tim.login({
            userID: sessionStorage.getItem('id')||'',
            userSig: sessionStorage.getItem('userSig')||''
        });
    });
}

export default function TestTalk(props:any) {
    // init IM
    const [tim, setTim] = useState<ChatSDK>();
    // render Twice tim=>undefined,tim=>{}
    useEffect(() => {
        (async ()=>{
            const tim = await init()
            setTim(tim)
        })()
    }, [])

    return (
        <Layout>
            <Profile tim={tim}/>
            <WebChat tim={tim}/>
        </Layout>
    );
}

