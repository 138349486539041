import React, { PropsWithChildren } from 'react';


interface UploadProps {
    type?: string,
    accept?: string,
    uuid?:string,
    onChange?: (files: HTMLInputElement | File) => void,
}

export function useUploadElement<
    T extends UploadProps
>(props:PropsWithChildren<T>):React.ReactElement {
    const {
        children,
        type,
        accept,
        onChange,
        uuid
    } = props;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length&&e.target.files.length > 0) {
            const { files } = e.target;
            const options = {
                type: files[0].type,
            };

            const file = new File(files as any, files[0].name, options);
            onChange&&onChange(file);
        }
        // if (e?.target?.files?.length&&e?.target?.files?.length > 0) {
        //     const { files } = e.target;
        //     const fileLength=files.length||0;
        //
        //     for (let i=0;i<fileLength;i++){
        //         const currentFile=files[i];
        //         const options = {
        //             type:currentFile.type,
        //         };
        //         onChange&&onChange(currentFile);
        //     }
        // }
        e.target.value = '';
    };
    return (
        <div className="upload-picker" style={{minWidth:'32px',padding:0}}>
            <main className="upload-picker-content">{children}</main>
            {/*z-index is use for  clicking the icon open the upload components*/}
            <input style={{zIndex:3}} title="图片" type="file" data-type={type} data-uuid={uuid} accept={accept} onChange={handleChange} />
        </div>
    );
}


/**/
export enum MESSAGE_TYPE_NAME {
    TEXT = 'text',
    CUSTOM = 'custom',
    IMAGE = 'image',
    AUDIO = 'audio',
    VIDEO = 'video',
    FILE = 'file',
    FACE = 'face',
}