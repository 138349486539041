import {Buffer} from "buffer";
export function searchAttribute(obj: any, attribute: string): any | undefined {
    if (obj.hasOwnProperty(attribute)) {
        return obj[attribute];
    }

    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            const result = searchAttribute(obj[key], attribute);
            if (result !== undefined) {
                return result;
            }
        }
    }

    return undefined;
}

export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export  function base64Encode(str:string) {
    return Buffer.from(str).toString('base64');
}

export function base64Decode(b64str:string) {
    return  Buffer.from(b64str, 'base64').toString();
}