import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';
import './index.css';
interface layoutProps {
    // style: CSSProperties,
    // className: string,
    children:ReactNode
}
export default function Layout(props:layoutProps) {

    return (
        <div className={'main-layout'}>
            {props.children}
        </div>
    );
}

