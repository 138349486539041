import React, {forwardRef, useState} from "react";
import {useTUIMessageInputContext} from "@tencentcloud/chat-uikit-react";
import {useUploadElement,MESSAGE_TYPE_NAME} from "../util/upload/uploadhooks";
import {CloseFillIcon, PictureIcon} from "../util/icon";
import {generateUUID} from "../util/common";
import FloatingPanel from "../components/floatingPanel";


const PreUpload=forwardRef((props:any, ref) =>{
    const {
        sendUploadMessage,
    } = useTUIMessageInputContext('Transmitter');//TUIMessageInputDefault  Transmitter

    React.useImperativeHandle(ref, () => ({
        sendCurrentFileList,removeAll,sendUploadMessage
    }));
    const sendCurrentFileList=(param?:string)=>{
        return fileList
    }

    const [fileList,setFileList]=useState<Array<any>>([]);
     // FilePicker
    const FilePicker = useUploadElement({
        children: (<PictureIcon width={25} height={25}/>),
        type: 'file',
        accept: 'file/*',
        onChange: (file: any) => {
            if(file){
                const array1=[ Object.assign( file, {
                    uuid: generateUUID(),
                })];
                const bbb=[...array1,...fileList];
                setFileList(bbb)
            }},
    });

    const removeFile=(file:any)=>{
        const restList=fileList.filter(item => item?.uuid !== file?.uuid);
        setFileList(restList);
    }
    const removeAll=()=>{
        setFileList([]);
    }

    return (<>
        {fileList.length>0&&<div style={{
            position:"absolute",
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            top:0,
            left:0,
            zIndex:0,
            background:'#ffffff',
        }}>
            <FloatingPanel anchors={[70,180,300]}>
                <div className={'file-list'}>
                    {
                        fileList.map((file: any) => {
                            switch (file.type){
                                case 'image/png':
                                    return (
                                        <div className={'file-list-item'}>
                                            <CloseFillIcon
                                                className={'middle-icon file-list-close'}
                                                width={24}
                                                height={24}
                                                onClick={()=>{
                                                    removeFile(file)
                                                }}
                                            />
                                            <a href={URL.createObjectURL(file)} target="_blank">
                                                <img
                                                    className={'file-list-img-item'}
                                                    src={URL.createObjectURL(file)}
                                                    alt="Image"
                                                />
                                            </a>
                                        </div>
                                    );
                                    case 'video/mp4':
                                        return (
                                            <div className={'file-list-item'}>
                                                <CloseFillIcon
                                                    className={'middle-icon file-list-close'}
                                                    width={24}
                                                    height={24}
                                                    onClick={()=>{
                                                        removeFile(file)
                                                    }}
                                                />
                                                <video
                                                    className={'file-list-video-item'}
                                                    controls
                                                >
                                                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div className={'file-list-item'}>
                                                <CloseFillIcon
                                                    className={'middle-icon file-list-close'}
                                                    width={24}
                                                    height={24}
                                                    onClick={()=>{
                                                        removeFile(file)
                                                    }}
                                                />
                                                <a href={URL.createObjectURL(file)} target="_blank">
                                                    <div className={'file-list-file-item'}>
                                                        <PictureIcon className={'file-list-icon'} width={25} height={25}/>
                                                        {file.name}
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                }
                            })
                        }
                    </div>

                </FloatingPanel>
            </div>}
            {FilePicker}
        </>)
});
export default PreUpload;