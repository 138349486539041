import React, {useEffect, useMemo, useState} from 'react';
import {TUIKit,TUIChat, useTUIKit, useTUIKitContext} from '@tencentcloud/chat-uikit-react';
import '@tencentcloud/chat-uikit-react/dist/cjs/index.css';
import FaceConversation from './faceconversation';
import FaceChatTabs from './facechattabs';

export default function WebChat(props:any)
{
    const tim=props.tim;

    return (
        <div style={{height: '100vh',width: '100vw',overflow:'hidden'}}>
            {tim ? <TUIKit children={
                <div style={{display:'flex',width:`100%`}}>
                    <FaceConversation/>
                    <FaceChatTabs/>
                </div>
            } tim={tim}></TUIKit>:<p>loading</p>}
        </div>
    );
}