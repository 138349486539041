export interface IEmojiMap {
    [key:string]:string
}
export interface IBigEmojiListItem {
    icon: number,
    list: Array<string>,
}
export const emojiUrl = 'https://web.sdk.qcloud.com/im/assets/emoji/';
export const emojiMap:IEmojiMap = {
    '[NO]': 'emoji_0@2x.png',
    '[OK]': 'emoji_1@2x.png',
    '[下雨]': 'emoji_2@2x.png',
    '[么么哒]': 'emoji_3@2x.png',
    '[乒乓]': 'emoji_4@2x.png',
    '[便便]': 'emoji_5@2x.png',
    '[信封]': 'emoji_6@2x.png',
    '[偷笑]': 'emoji_7@2x.png',
    '[傲慢]': 'emoji_8@2x.png',
    '[再见]': 'emoji_9@2x.png',
    '[冷汗]': 'emoji_10@2x.png',
    '[凋谢]': 'emoji_11@2x.png',
    '[刀]': 'emoji_12@2x.png',
    '[删除]': 'emoji_13@2x.png',
    '[勾引]': 'emoji_14@2x.png',
    '[发呆]': 'emoji_15@2x.png',
    '[发抖]': 'emoji_16@2x.png',
    '[可怜]': 'emoji_17@2x.png',
    '[可爱]': 'emoji_18@2x.png',
    '[右哼哼]': 'emoji_19@2x.png',
    '[右太极]': 'emoji_20@2x.png',
    '[右车头]': 'emoji_21@2x.png',
    '[吐]': 'emoji_22@2x.png',
    '[吓]': 'emoji_23@2x.png',
    '[咒骂]': 'emoji_24@2x.png',
    '[咖啡]': 'emoji_25@2x.png',
    '[啤酒]': 'emoji_26@2x.png',
    '[嘘]': 'emoji_27@2x.png',
    '[回头]': 'emoji_28@2x.png',
    '[困]': 'emoji_29@2x.png',
    '[坏笑]': 'emoji_30@2x.png',
    '[多云]': 'emoji_31@2x.png',
    '[大兵]': 'emoji_32@2x.png',
    '[大哭]': 'emoji_33@2x.png',
    '[太阳]': 'emoji_34@2x.png',
    '[奋斗]': 'emoji_35@2x.png',
    '[奶瓶]': 'emoji_36@2x.png',
    '[委屈]': 'emoji_37@2x.png',
    '[害羞]': 'emoji_38@2x.png',
    '[尴尬]': 'emoji_39@2x.png',
    '[左哼哼]': 'emoji_40@2x.png',
    '[左太极]': 'emoji_41@2x.png',
    '[左车头]': 'emoji_42@2x.png',
    '[差劲]': 'emoji_43@2x.png',
    '[弱]': 'emoji_44@2x.png',
    '[强]': 'emoji_45@2x.png',
    '[彩带]': 'emoji_46@2x.png',
    '[彩球]': 'emoji_47@2x.png',
    '[得意]': 'emoji_48@2x.png',
    '[微笑]': 'emoji_49@2x.png',
    '[心碎了]': 'emoji_50@2x.png',
    '[快哭了]': 'emoji_51@2x.png',
    '[怄火]': 'emoji_52@2x.png',
    '[怒]': 'emoji_53@2x.png',
    '[惊恐]': 'emoji_54@2x.png',
    '[惊讶]': 'emoji_55@2x.png',
    '[憨笑]': 'emoji_56@2x.png',
    '[手枪]': 'emoji_57@2x.png',
    '[打哈欠]': 'emoji_58@2x.png',
    '[抓狂]': 'emoji_59@2x.png',
    '[折磨]': 'emoji_60@2x.png',
    '[抠鼻]': 'emoji_61@2x.png',
    '[抱抱]': 'emoji_62@2x.png',
    '[抱拳]': 'emoji_63@2x.png',
    '[拳头]': 'emoji_64@2x.png',
    '[挥手]': 'emoji_65@2x.png',
    '[握手]': 'emoji_66@2x.png',
    '[撇嘴]': 'emoji_67@2x.png',
    '[擦汗]': 'emoji_68@2x.png',
    '[敲打]': 'emoji_69@2x.png',
    '[晕]': 'emoji_70@2x.png',
    '[月亮]': 'emoji_71@2x.png',
    '[棒棒糖]': 'emoji_72@2x.png',
    '[汽车]': 'emoji_73@2x.png',
    '[沙发]': 'emoji_74@2x.png',
    '[流汗]': 'emoji_75@2x.png',
    '[流泪]': 'emoji_76@2x.png',
    '[激动]': 'emoji_77@2x.png',
    '[灯泡]': 'emoji_78@2x.png',
    '[炸弹]': 'emoji_79@2x.png',
    '[熊猫]': 'emoji_80@2x.png',
    '[爆筋]': 'emoji_81@2x.png',
    '[爱你]': 'emoji_82@2x.png',
    '[爱心]': 'emoji_83@2x.png',
    '[爱情]': 'emoji_84@2x.png',
    '[猪头]': 'emoji_85@2x.png',
    '[猫咪]': 'emoji_86@2x.png',
    '[献吻]': 'emoji_87@2x.png',
    '[玫瑰]': 'emoji_88@2x.png',
    '[瓢虫]': 'emoji_89@2x.png',
    '[疑问]': 'emoji_90@2x.png',
    '[白眼]': 'emoji_91@2x.png',
    '[皮球]': 'emoji_92@2x.png',
    '[睡觉]': 'emoji_93@2x.png',
    '[磕头]': 'emoji_94@2x.png',
    '[示爱]': 'emoji_95@2x.png',
    '[礼品袋]': 'emoji_96@2x.png',
    '[礼物]': 'emoji_97@2x.png',
    '[篮球]': 'emoji_98@2x.png',
    '[米饭]': 'emoji_99@2x.png',
    '[糗大了]': 'emoji_100@2x.png',
    '[红双喜]': 'emoji_101@2x.png',
    '[红灯笼]': 'emoji_102@2x.png',
    '[纸巾]': 'emoji_103@2x.png',
    '[胜利]': 'emoji_104@2x.png',
    '[色]': 'emoji_105@2x.png',
    '[药]': 'emoji_106@2x.png',
    '[菜刀]': 'emoji_107@2x.png',
    '[蛋糕]': 'emoji_108@2x.png',
    '[蜡烛]': 'emoji_109@2x.png',
    '[街舞]': 'emoji_110@2x.png',
    '[衰]': 'emoji_111@2x.png',
    '[西瓜]': 'emoji_112@2x.png',
    '[调皮]': 'emoji_113@2x.png',
    '[象棋]': 'emoji_114@2x.png',
    '[跳绳]': 'emoji_115@2x.png',
    '[跳跳]': 'emoji_116@2x.png',
    '[车厢]': 'emoji_117@2x.png',
    '[转圈]': 'emoji_118@2x.png',
    '[鄙视]': 'emoji_119@2x.png',
    '[酷]': 'emoji_120@2x.png',
    '[钞票]': 'emoji_121@2x.png',
    '[钻戒]': 'emoji_122@2x.png',
    '[闪电]': 'emoji_123@2x.png',
    '[闭嘴]': 'emoji_124@2x.png',
    '[闹钟]': 'emoji_125@2x.png',
    '[阴险]': 'emoji_126@2x.png',
    '[难过]': 'emoji_127@2x.png',
    '[雨伞]': 'emoji_128@2x.png',
    '[青蛙]': 'emoji_129@2x.png',
    '[面条]': 'emoji_130@2x.png',
    '[鞭炮]': 'emoji_131@2x.png',
    '[风车]': 'emoji_132@2x.png',
    '[飞吻]': 'emoji_133@2x.png',
    '[飞机]': 'emoji_134@2x.png',
    '[饥饿]': 'emoji_135@2x.png',
    '[香蕉]': 'emoji_136@2x.png',
    '[骷髅]': 'emoji_137@2x.png',
    '[麦克风]': 'emoji_138@2x.png',
    '[麻将]': 'emoji_139@2x.png',
    '[鼓掌]': 'emoji_140@2x.png',
    '[龇牙]': 'emoji_141@2x.png',
};
export const emojiEnKey:IEmojiMap = {
    '[NO]': '[no]',
    '[OK]': '[ok]',
    '[下雨]': '[rain]',
    '[么么哒]': '[muah]',
    '[乒乓]': '[ping pong]',
    '[便便]': '[poo]',
    '[信封]': '[mail]',
    '[偷笑]': '[snicker]',
    '[傲慢]': '[arrogant]',
    '[再见]': '[bye]',
    '[冷汗]': '[sweat]',
    '[凋谢]': '[wither]',
    '[刀]': '[knife]',
    '[删除]': '[delete]',
    '[勾引]': '[hook]',
    '[发呆]': '[daze]',
    '[发抖]': '[shaking]',
    '[可怜]': '[pitiful]',
    '[可爱]': '[cute]',
    '[右哼哼]': '[right hum]',
    '[右太极]': '[right Tai Chi]',
    '[右车头]': '[right head]',
    '[吐]': '[vomit]',
    '[吓]': '[scare]',
    '[咒骂]': '[curse]',
    '[咖啡]': '[cafe]',
    '[啤酒]': '[beer]',
    '[嘘]': '[hiss]',
    '[回头]': '[look back]',
    '[困]': '[sleepy]',
    '[坏笑]': '[bad laugh]',
    '[多云]': '[cloud]',
    '[大兵]': '[soldiers]',
    '[大哭]': '[cry]',
    '[太阳]': '[sun]',
    '[奋斗]': '[struggle]',
    '[奶瓶]': '[feeding bottle]',
    '[委屈]': '[wronged]',
    '[害羞]': '[shy]',
    '[尴尬]': '[awkward]',
    '[左哼哼]': '[left hem]',
    '[左太极]': '[left Tai Chi]',
    '[左车头]': '[left head]',
    '[差劲]': '[poor]',
    '[弱]': '[weak]',
    '[强]': '[stong]',
    '[彩带]': '[ribbon]',
    '[彩球]': '[ball]',
    '[得意]': '[proud]',
    '[微笑]': '[smile]',
    '[心碎了]': '[heartbroken]',
    '[快哭了]': '[almost cry]',
    '[怄火]': '[ire]',
    '[怒]': '[anger]',
    '[惊恐]': '[terrify]',
    '[惊讶]': '[flummox]',
    '[憨笑]': '[smirk]',
    '[手枪]': '[gun]',
    '[打哈欠]': '[yawn]',
    '[抓狂]': '[crazy]',
    '[折磨]': '[torment]',
    '[抠鼻]': '[nose picking]',
    '[抱抱]': '[hug]',
    '[抱拳]': '[hug fist]',
    '[拳头]': '[fist]',
    '[挥手]': '[waving hands]',
    '[握手]': '[shake hands]',
    '[撇嘴]': '[pouted]',
    '[擦汗]': '[wipe sweat]',
    '[敲打]': '[knock on the head]',
    '[晕]': '[faint]',
    '[月亮]': '[moon]',
    '[棒棒糖]': '[lollipop]',
    '[汽车]': '[car]',
    '[沙发]': '[sofa]',
    '[流汗]': '[sweat]',
    '[流泪]': '[tears]',
    '[激动]': '[excitement]',
    '[灯泡]': '[light]',
    '[炸弹]': '[bomb]',
    '[熊猫]': '[panda]',
    '[爆筋]': '[exploding muscle]',
    '[爱你]': '[love you]',
    '[爱心]': '[love heart]',
    '[爱情]': '[love]',
    '[猪头]': '[pig]',
    '[猫咪]': '[cat]',
    '[献吻]': '[kiss]',
    '[玫瑰]': '[rose]',
    '[瓢虫]': '[ladybird]',
    '[疑问]': '[what]',
    '[白眼]': '[rolling eyes]',
    '[皮球]': '[rubber ball]',
    '[睡觉]': '[sleep]',
    '[磕头]': '[kowtow]',
    '[示爱]': '[show love]',
    '[礼品袋]': '[gift bag]',
    '[礼物]': '[gift]',
    '[篮球]': '[basketball]',
    '[米饭]': '[rice]',
    '[糗大了]': '[shame]',
    '[红双喜]': '[happiness]',
    '[红灯笼]': '[lantern]',
    '[纸巾]': '[tissue]',
    '[胜利]': '[victory]',
    '[色]': '[lecherous]',
    '[药]': '[medicine]',
    '[菜刀]': '[kitchen knife]',
    '[蛋糕]': '[coke]',
    '[蜡烛]': '[candle]',
    '[街舞]': '[hip hop]',
    '[衰]': '[decline]',
    '[西瓜]': '[lemon]',
    '[调皮]': '[naughty]',
    '[象棋]': '[chess]',
    '[跳绳]': '[jump rope]',
    '[跳跳]': '[jump]',
    '[车厢]': '[compartment]',
    '[转圈]': '[turn in circles]',
    '[鄙视]': '[contempt]',
    '[酷]': '[cool]',
    '[钞票]': '[money]',
    '[钻戒]': '[diamond]',
    '[闪电]': '[lightning]',
    '[闭嘴]': '[shut up]',
    '[闹钟]': '[clock]',
    '[阴险]': '[sinister]',
    '[难过]': '[sad]',
    '[雨伞]': '[umbrella]',
    '[青蛙]': '[frog]',
    '[面条]': '[noodles]',
    '[鞭炮]': '[firecracker]',
    '[风车]': '[windmill]',
    '[飞吻]': '[blow kisses]',
    '[飞机]': '[air]',
    '[饥饿]': '[hunger]',
    '[香蕉]': '[banana]',
    '[骷髅]': '[skull]',
    '[麦克风]': '[mic]',
    '[麻将]': '[mahjong]',
    '[鼓掌]': '[handclap]',
    '[龇牙]': '[happy]',
};
export const emojiName:Array<string> = [
    '[龇牙]',
    '[调皮]',
    '[流汗]',
    '[偷笑]',
    '[再见]',
    '[敲打]',
    '[擦汗]',
    '[猪头]',
    '[玫瑰]',
    '[流泪]',
    '[大哭]',
    '[嘘]',
    '[酷]',
    '[抓狂]',
    '[委屈]',
    '[便便]',
    '[炸弹]',
    '[菜刀]',
    '[可爱]',
    '[色]',
    '[害羞]',
    '[得意]',
    '[吐]',
    '[微笑]',
    '[怒]',
    '[尴尬]',
    '[惊恐]',
    '[冷汗]',
    '[爱心]',
    '[示爱]',
    '[白眼]',
    '[傲慢]',
    '[难过]',
    '[惊讶]',
    '[疑问]',
    '[困]',
    '[么么哒]',
    '[憨笑]',
    '[爱情]',
    '[衰]',
    '[撇嘴]',
    '[阴险]',
    '[奋斗]',
    '[发呆]',
    '[右哼哼]',
    '[抱抱]',
    '[坏笑]',
    '[飞吻]',
    '[鄙视]',
    '[晕]',
    '[大兵]',
    '[可怜]',
    '[强]',
    '[弱]',
    '[握手]',
    '[胜利]',
    '[抱拳]',
    '[凋谢]',
    '[米饭]',
    '[蛋糕]',
    '[西瓜]',
    '[啤酒]',
    '[瓢虫]',
    '[勾引]',
    '[OK]',
    '[爱你]',
    '[咖啡]',
    '[月亮]',
    '[刀]',
    '[发抖]',
    '[差劲]',
    '[拳头]',
    '[心碎了]',
    '[太阳]',
    '[礼物]',
    '[皮球]',
    '[骷髅]',
    '[挥手]',
    '[闪电]',
    '[饥饿]',
    '[咒骂]',
    '[折磨]',
    '[抠鼻]',
    '[鼓掌]',
    '[糗大了]',
    '[左哼哼]',
    '[打哈欠]',
    '[快哭了]',
    '[吓]',
    '[篮球]',
    '[乒乓]',
    '[NO]',
    '[跳跳]',
    '[怄火]',
    '[转圈]',
    '[磕头]',
    '[回头]',
    '[跳绳]',
    '[激动]',
    '[街舞]',
    '[献吻]',
    '[左太极]',
    '[右太极]',
    '[闭嘴]',
    '[猫咪]',
    '[红双喜]',
    '[鞭炮]',
    '[红灯笼]',
    '[麻将]',
    '[麦克风]',
    '[礼品袋]',
    '[信封]',
    '[象棋]',
    '[彩带]',
    '[蜡烛]',
    '[爆筋]',
    '[棒棒糖]',
    '[奶瓶]',
    '[面条]',
    '[香蕉]',
    '[飞机]',
    '[左车头]',
    '[车厢]',
    '[右车头]',
    '[多云]',
    '[下雨]',
    '[钞票]',
    '[熊猫]',
    '[灯泡]',
    '[风车]',
    '[闹钟]',
    '[雨伞]',
    '[彩球]',
    '[钻戒]',
    '[沙发]',
    '[纸巾]',
    '[手枪]',
    '[青蛙]',
];
export const faceUrl = 'https://web.sdk.qcloud.com/im/assets/face-elem/';

export const bigEmojiList:Array<IBigEmojiListItem> = [
    {
        icon: 1,
        list: ['yz00', 'yz01', 'yz02', 'yz03', 'yz04', 'yz05', 'yz06', 'yz07', 'yz08',
            'yz09', 'yz10', 'yz11', 'yz12', 'yz13', 'yz14', 'yz15', 'yz16', 'yz17'],
    },
    {
        icon: 2,
        list: ['ys00', 'ys01', 'ys02', 'ys03', 'ys04', 'ys05', 'ys06', 'ys07', 'ys08',
            'ys09', 'ys10', 'ys11', 'ys12', 'ys13', 'ys14', 'ys15'],
    },
    {
        icon: 3,
        list: ['gcs00', 'gcs01', 'gcs02', 'gcs03', 'gcs04', 'gcs05', 'gcs06', 'gcs07',
            'gcs08', 'gcs09', 'gcs10', 'gcs11', 'gcs12', 'gcs13', 'gcs14', 'gcs15', 'gcs16'],
    },
];

/**
 * Convert to expression key
 * @param { string } data text
 * @param { number } type 0:Convert to Chinese expression key 1: Convert to English expression key
 * @returns { string } the converted text
 */
export function formatEmojiString(data: string, type = 0) {
    let text = data;
    const replace = {
        key: type === 0 ? 1 : 0,
        value: type,
    };
    Object.entries(emojiEnKey).map((item) => {
        if (text.includes(item[replace.key])) {
            text = transText(text, item[replace.key], item[replace.value]);
        }
        return item;
    });
    return text;
}

/**
 * recursive replacement text
 * @param { string } data text
 * @param { string } replaceVal the value to replace
 * @param { string } value replace Value
 * @returns { string } the replaced value
 */
function transText(data: string, replaceVal:string, value: string) {
    let text = data;
    text = text.replace(replaceVal, value);
    if (text.includes(replaceVal)) {
        text = transText(text, replaceVal, value);
    }
    return text;
}
