import React, {CSSProperties, ReactNode, useEffect, useMemo, useState} from 'react';
import {Avatar, Icon, IconTypes, Popup,useProfile} from '@tencentcloud/chat-uikit-react';
import { useNavigate } from "react-router-dom";
import { Conversation, Profile as CuProfile } from 'tim-js-sdk';
import {Logo, SettingIcon, DefiIcon, StakingIcon} from '../../util/icon';
import './index.css';

export default function Profile(props:any) {
    const tim=props.tim;
    const navigate=useNavigate();
    const [show, setShow] = useState(false);
    const [className, setClassName] = useState('');
    const [profileInfo,setProfileInfo]=useState<CuProfile>()
    const handleShow = () => {
        setShow(!show);
    };

    const getMyProfile = async () => {
        const res = await tim?.getMyProfile();
        setProfileInfo(res?.data);
    };
    useEffect(() => {
        getMyProfile();
    }, [tim]);

    const handleVisible = () => {
        setClassName('emoji-plugin-top');
    };
    const  exite=()=>{
        tim.logout().then((imResponse:any)=>{
            console.log(imResponse.data); // Logout successful
            navigate('/');
            sessionStorage.clear();
        }).catch((imError:any)=>{
            console.log(imError);
        });
    }
    return (
        <div className={'face-profile'}>
            <div>
                <div className={'face-profile-logo'}>
                    <Logo
                        style={{paddingTop: 11}}
                    />
                </div>
                <div className={'face-profile-userInfo'}>
                    <div className={'face-profile-avatar'}>
                        <Avatar
                            size = {50}
                            shape = {'square'}
                            image={profileInfo?.avatar}
                        />
                    </div>
                    <div className={'face-profile-name'}>{profileInfo?.nick||'-'}</div>
                </div>

                <div className={'face-profile-logo'}>
                    <StakingIcon
                    />
                </div>
            </div>
            <div className={'face-profile-exit'} id={'logout'}>
                <SettingIcon
                    width={30}
                    height={30}
                    onClick={handleShow}
                />
                <Popup
                    className={`input-plugin-popup-box setting-menu`}
                    show={show}
                    close={handleShow}
                    handleVisible={handleVisible}
                >
                    <ul className="tui-profile-list">
                        <li className="tui-profile-list-item">
                            <h4>{profileInfo?.nick}</h4>
                            <h4>{profileInfo?.selfSignature}</h4>
                        </li>
                        <li className="tui-profile-list-item">
                            <div onClick={()=>{
                                exite();
                            }} style={{display:'flex',justifyContent:'space-between', alignItems: 'center',cursor:'pointer'}}>
                                <h4>logout</h4>
                            </div>
                        </li>
                    </ul>
                </Popup>
            </div>
        </div>
    );
}

