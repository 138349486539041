import React from 'react';
import logo from './logo.svg'
import { BrowserRouter,Routes,Route} from 'react-router-dom';

import WebChat from './pages/im';
import Login from './pages/login';
import Layout from './pages/components/layout';
import './App.css';
import TestTalk from "./pages/testim";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                {/*<Route path="/webchat" element={<WebChat />} />*/}
                <Route path="/testtalk" element={<TestTalk/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
