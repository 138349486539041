import React, {useEffect, useState } from 'react';
import ScanQrcode from '../components/qrcode';
import { useNavigate } from "react-router-dom";
import {Logo} from '../util/icon';
import './index.css';
export default function Login() {
    const navigate=useNavigate();
    return (
        <div className={'login-bg'}>
            <div className={'login-logo'}>
                <Logo
                    width={30}
                    height={30}
                />
            </div>
            <div className={'login-inner'}>
                <div className={'login-qrcode'}>
                    <ScanQrcode redirect={()=>navigate('/testtalk')}/>
                </div>
            </div>
            <div className={'login-footer'}>©Copyright by FaceDadao Inc.All rights reserved.</div>
        </div>
    );
}

