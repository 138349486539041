import { useCallback, useEffect, useRef } from 'react';
export async function postData(url: string, data: any) {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Request error:', error);
        throw error;
    }

}
