import React, { useEffect, useMemo, useRef, useState} from 'react';
import {
    ConversationSearchInput,
    ConversationSearchResult,
    useTUIKitContext,
    TUIConversation,
    TUIChat,
    Popup,
} from '@tencentcloud/chat-uikit-react';
import {CloseIcon, EditIcon, QrcodeIcon} from '../util/icon';
import TIM from 'tim-js-sdk';
import './index.css';
import FaceChatTab from './facechattab';
interface chatInstance{
    id:string;
    chatInstance:any | undefined;
}
export default function FaceChatTabs(props:any) {
    const {
        tim, customClasses, conversation, setActiveConversation,TUIProfileShow,setTUIManageShow, setTUIProfileShow,
    } = useTUIKitContext('TUIConversationList');
    const [chats, setChats] = useState<chatInstance[]>([]);
    const refArray =Array(4).fill( useRef<HTMLDivElement>(null) );
    const MAX_CHAT_LENGTH=4;

    useEffect(()=>{
        if(conversation&&conversation?.conversationID){
            const isObjectInArray= chats.some(obj => obj?.id === conversation?.conversationID);
            if(chats.length < MAX_CHAT_LENGTH){
                if(!isObjectInArray ){
                    addChat(conversation);
                }
                setActiveConversation(conversation)
            }else if(chats.length === MAX_CHAT_LENGTH){
                if(!isObjectInArray){
                    removeChat(chats,undefined);
                }else{
                    setActiveConversation(conversation)
                }
            }
        }

    },[conversation?.conversationID])

    const addChat=(conversationIns:any)=>{
        const newChat = {
            id: conversationIns?.conversationID,
            chatInstance: conversationIns,
        };
        setChats([...chats, newChat]);
    }
    const removeChat=(chatArr:any,chatId?:string)=>{
        setActiveConversation(undefined)
        const tempChats=JSON.parse(JSON.stringify(chatArr));
        if(!chatId){
            tempChats.shift();
            const newChat = {
                id: conversation?.conversationID,
                chatInstance: conversation,
            };
            setChats([...tempChats,newChat]);
            return true
        }else{
            const chatIndex = tempChats.findIndex((obj:any) => obj.id === chatId);
            // console.log("=====delete",chatId,chatArr)
            if (chatIndex !== -1) {
                tempChats.splice(chatIndex, 1);
                // console.log("=====chatIS", tempChats);
                setChats(tempChats);
                return true
            }
            // return false
        }
    }

    const freshChat=(chatId?:string)=>{
        if(chatId){
            const promise = tim.getGroupProfile({ groupID: chatId});
            promise.then(function(imResponse) {
                // console.log("=========更新后重新获取",imResponse.data.group);

            }).catch(function(imError) {
                // console.warn('getGroupProfile error:', imError); // Error information
            });
        }
    }

    return (
        <div className={'chat-content'}>
            {chats.map((chat,index) => (
                <FaceChatTab
                    isActive={conversation?.conversationID===chat?.id}
                    ref={refArray[index]}
                    chatId={chat.id}
                    key={chat.id}
                    conversation={chat.chatInstance}
                    removeCurrentChat={(param:any)=>{
                        removeChat(chats,param)
                    }}
                    freshCurrentChat={()=>freshChat(chat.id)}
                />
            ))}

        </div>
    );
}

