import React, {CSSProperties, ReactNode, useEffect, useMemo, useState} from 'react';
import {
    ConversationSearchInput,
    ConversationSearchResult,
    useTUIKitContext,
    TUIConversationList,
    TUIConversation,
    TUIChat,ConversationPreviewContent
} from '@tencentcloud/chat-uikit-react';
import TIM from 'tim-js-sdk';
import './index.css';

export default function FaceConversation(props:any) {

    const {tim}=useTUIKitContext();
    // const FaceConversationList=()=>{
    //     // 获取用户列表
    //     tim.getFriendList().then(function(imResponse) {
    //        // const conversationList = imResponse.data; // This conversation list will overwrite the original conversation list.
    //         console.log("DDD",imResponse)
    //     }).catch(function(imError) {
    //         console.warn('getConversationList error:', imError); // Error information
    //     });
    // //     获取群组列表
    //     tim.getGroupList({
    //         groupProfileFilter: [TIM.TYPES.GRP_PROFILE_OWNER_ID],
    //     }).then(function(imResponse) {
    //         // const conversationList = imResponse.data; // This conversation list will overwrite the original conversation list.
    //         console.log("SSSSSAAAA",imResponse)
    //     }).catch(function(imError) {
    //         console.warn('getConversationList error:', imError); // Error information
    //     });
    // //     获取会话列表
    //     tim.getConversationList().then(function(imResponse) {
    //         const conversationList = imResponse.data.conversationList; // This conversation list will overwrite the original conversation list.
    //         console.log("======",conversationList)
    //     }).catch(function(imError) {
    //         console.warn('getConversationList error:', imError); // Error information
    //     });
    // }
    // const [searchValue, setSearchValue] = useState('');
    // const [searchResult, setSearchResult] = useState([]);
    // const handleSearchValueChange = (e:any) => {
    //     setSearchValue(e.target?.value);
    //     if (e.target?.value) {
    //         // const result = conversationList.filter(
    //         //     // eslint-disable-next-line max-len
    //         //     (item) => (getDisplayTitle(item) as string).toLocaleLowerCase().includes(e.target?.value.toLocaleLowerCase()),
    //         // );
    //         // setSearchResult(result);
    //         console.log("sSSS",searchValue)
    //     } else {
    //         // setSearchResult([]);
    //     }
    // };
    useMemo(()=>{
        // FaceConversationList();
    },[tim])
    return (
        <div className={'face-conversation'}>
            <TUIConversationList
                filterConversation={(conversationList)=>{
                    return conversationList.filter(i=>!['ChatGPTBot','SwapBot','SystemBot','SocialBot'].includes(i?.userProfile?.userID))
                }}
                Preview={(props)=>{
                    const CurrPreView=ConversationPreviewContent;
                    const {conversation:{remark=''},displayTitle}=props;
                    return <CurrPreView {...props} displayTitle={remark!==''?remark:displayTitle}/>
                }}
            />
        </div>

    );
}
