import React, {
    forwardRef,
    ReactNode,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import "./index.css";

export type FloatingPanelRef = {
    setHeight: (
        height: number,
        options?: {
            immediate?: boolean;
        }
    ) => void;
};
export type FloatingPanelProps = {
    anchors: number[];
    children: ReactNode;
    onHeightChange?: (height: number, animating: boolean) => void;
    handleDraggingOfContent?: boolean;
};

function nearest(arr: number[], target: number) {
    return arr.reduce((pre, cur) => {
        return Math.abs(pre - target) < Math.abs(cur - target) ? pre : cur;
    });
}
const FloatingPanel = forwardRef<FloatingPanelRef, FloatingPanelProps>(
    (props, ref) => {
        const { anchors, onHeightChange } = props;
        const maxHeight = anchors[anchors.length - 1] ?? window.innerHeight;

        const possibles = anchors.map((x) => -x);
        const elementRef = useRef<HTMLDivElement>(null);
        const headerRef = useRef<HTMLDivElement>(null);
        const contentRef = useRef<HTMLDivElement>(null);
        const [pulling, setPulling] = useState(false);
        const [stopHeight,setStopHeight]=useState(70);
        const pullingRef = useRef(false);

        const bounds = {
            top: possibles[possibles.length - 1],
            bottom: possibles[0]
        };

        const [{ y }, api] = useSpring(() => ({
            y: bounds.bottom,
            config: { tension: 300 },
            onChange: (result) => {
                onHeightChange && onHeightChange(-result.value.y, y.isAnimating);
                setStopHeight(-result.value.y)
            }
        }));

        useDrag(
            (state) => {
                const [, offsetY] = state.offset;
                if (state.first) {
                    const target = state.event.target as Element;
                    const header = headerRef.current;
                    if (header === target || header?.contains(target)) {
                        pullingRef.current = true;
                    } else {
                        if (!props.handleDraggingOfContent) return;
                        const reachedTop = y.goal <= bounds.top;
                        const content = contentRef.current;
                        if (!content) return;
                        if (reachedTop) {
                            if (content.scrollTop <= 0 && state.direction[1] > 0) {
                                pullingRef.current = true;
                            }
                        } else {
                            pullingRef.current = true;
                        }
                    }
                }
                setPulling(pullingRef.current);
                if (!pullingRef.current) return;
                const { event } = state;
                if (event.cancelable) {
                    event.preventDefault();
                }
                event.stopPropagation();
                let nextY = offsetY;
                if (state.last) {
                    pullingRef.current = false;
                    setPulling(false);
                    nextY = nearest(possibles, offsetY);
                }
                api.start({
                    y: nextY
                });
            },
            {
                axis: "y",
                bounds,
                rubberband: true,
                from: () => [0, y.get()],
                pointer: { touch: true },
                target: elementRef,
                eventOptions: { passive: false }
            }
        );

        useImperativeHandle(
            ref,
            () => ({
                setHeight: (
                    height: number,
                    options?: {
                        immediate?: boolean;
                    }
                ) => {
                    api.start({
                        y: -height,
                        immediate: options?.immediate
                    });
                }
            }),
            [api]
        );
        return (
            <animated.div
                ref={elementRef}
                style={{
                    background:'#fff',
                    height: Math.round(maxHeight),
                    translateY: y.to(y => `calc(100% + (${Math.round(y)}px))`),
                }}
            >
                <div
                    style={{
                        display: pulling ? 'block' : 'none',
                    }}
                />
                <div style={{height:'10px',background:'#fff',cursor:'grab',paddingTop:'4px'}} ref={headerRef}>
                    <div className='floating-panel-bar' />
                </div>
                <div className='adm-floating-panel-content' ref={contentRef}  style={{
                    display: (pulling||stopHeight>100) ? 'block' : 'none',
                    height:maxHeight - (70 + 50)
                }}>
                    {props.children}
                </div>
            </animated.div>
        );
    }
);
FloatingPanel.displayName = "FloatingPanel";
export default FloatingPanel;
