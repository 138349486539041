import React, { useState,forwardRef } from 'react';
import { useTUIMessageInputContext,Popup, Avatar,defaultGroupAvatarWork } from '@tencentcloud/chat-uikit-react';
import type { EmojiData } from '../util/emoji/useemojipicker';

export const AtMemberPicker=forwardRef((props:any, ref) => {
    const {conversation,tim,sendDirectMessage,memberList}=props;
    const [show, setShow] = useState(false);
    const [className, setClassName] = useState('');
    // const [memberList,setMemberList]=useState([])

    const handleShow = () => {
      setShow(!show);
    };
    const handleHide = () => {
      setShow(false);
    };
    const handleVis = () => {
      setShow(true);
    };
    React.useImperativeHandle(ref, () => ({
      handleShow,handleHide,handleVis
    }));

    const handleVisible = (data:any) => {
        setClassName(`${!data.bottom && 'pop-pos'} ${!data.right && 'pop-pos'}`);
    };

    return (
        <div className={`input-plugin-popup ${className}`}>
            <Popup
                className={`input-plugin-popup-box`}
                show={show}
                close={handleShow}
                handleVisible={handleVisible}
            >
                <ul className='face-at-list'>
                  <li
                    role="menuitem"
                    className='face-at-list-item'
                    key={`all`}
                    onClick={()=>{
                      sendDirectMessage(memberList,true)
                    }}
                  >
                    <Avatar
                      image={defaultGroupAvatarWork}
                      size={30}
                    />
                    <span>All Members</span>
                  </li>
                  <div className='divider'>Group Members</div>
                    {
                        memberList.map((item:any, index:number) => {
                            const key = item.userID;
                            return (
                                <li
                                    role="menuitem"
                                    className='face-at-list-item'
                                    key={key}
                                    onClick={()=>{
                                      sendDirectMessage(item)
                                    }}
                                >
                                  <Avatar
                                    image={item?.avatar}
                                    size={30}
                                    />
                                  <span>{item?.nick}</span>
                                </li>
                            );
                        })
                    }
                </ul>
            </Popup>
        </div>
    );
});
